<template>
<div class="tour" v-if="currentTour">

  <div class="tour__title">{{currentTour.title}}</div>

  <div class="tour__body">

    <button class="tour__prev" @click="prev()" :disabled="!currentTour.hotspots[currentHotspotIndex - 1]">
      <i uk-icon="icon: chevron-left;"></i>
    </button>

    <ul class="tour__locations">
      <li v-for="(hotspot,index) in currentTour.hotspots" :key="hotspot" :class="(currentHotspotIndex === index) ? 'current' : ''" @click="goToIndex(index)"></li>
    </ul>

    <button class="tour__next" @click="next()" :disabled="!currentTour.hotspots[currentHotspotIndex + 1]">
      <i uk-icon="icon: chevron-right;"></i>
    </button>

    <button class="tour__cancel" @click="cancel()">
      <i uk-icon="icon: close;"></i>
    </button>

  </div>
</div>
</template>


<script>
import {
  bus
} from '../main'


export default {
  name: 'Tour',
  data: function() {
    return {
      currentTour: false,
      currentHotspotIndex: false
    }
  },
  watch: {
    currentHotspotIndex: function(index) {
      if (index !== false){
        this.openContent(index)
      }
    }
  },
  components: {

  },
  computed: {
    allTours: function() {
      let allTours = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this._vaData.typo3Tours)) {
        allTours = [...allTours, ...value.items];
      }

      return allTours;
    }
  },
  methods: {
    next: function() {

      if (this.currentTour.hotspots[this.currentHotspotIndex + 1]) {
        this.currentHotspotIndex++
      }
    },
    prev: function() {
      if (this.currentTour.hotspots[this.currentHotspotIndex - 1]) {
        this.currentHotspotIndex--
      }
    },
    start: function(uid) {
      this.currentTour = this.allTours.find(t => t.uid === uid);
      this.currentHotspotIndex = 0;
    },
    cancel: function() {
      this.currentTour = false;
      this.currentHotspotIndex = false;
    },
    goToIndex: function(index) {
      this.currentHotspotIndex = index;
    },
    openContent: function(index) {
      const uid = this.currentTour.hotspots[index];
      bus.$emit('show_content', uid);

    }
  },
  created() {
    bus.$on('start_tour', (uid) => {
      this.start(uid)
    })
  }
}
</script>

<style lang="scss" scoped>
.tour {
    position: relative;
    // transition: transform 0.5s;

    .vm--block-scroll & {
        // content  modal is open
        // transform: translateX(100%);
    }

    &:after {
        content: '';
        top: -80px;
        bottom: -200px;
        left: -150px;
        right: -250px;
        background: rgba(#fff, .8);

        border-radius: 50%;
        z-index: -1;
        position: absolute;
        filter: blur(30px);
        margin: auto;
    }
}

.tour__title {
    // padding-left: 55px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;

}

.tour__body {
    display: grid;
    grid-template-columns: minmax(25px, 50px) auto minmax(25px, 50px) minmax(25px, 50px);
    align-items: center;
    grid-gap: 5px;
}

.tour__locations {
    padding: 0;
    margin: 0;
    display: grid;

    list-style: none;
    background: rgba(100,100,100,0.2);
    border-radius: 3px;
    padding: 8px 10px;

    // grid-template-columns: 50px 50px 50px 50pxs;
    grid-auto-columns: 11px;
    grid-auto-flow: column;
    grid-gap: 5px;

    @media (max-width: $breakpoint-small) {
        grid-auto-columns: 6px;
        grid-gap: 3px;
    }

    li {
        height: 11px;
        background: #fff;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        @media (max-width: $breakpoint-small) {
            height: 6px;
        }

        &:after {
            content: '';
            position: absolute;
            height: 5px;
            width: 5px;

            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            @media (max-width: $breakpoint-small) {
                height: 3px;
                width: 3px;
            }
        }

        &:hover {
            &:after {
                background: rgba(#000, .4);
            }
        }

        &.current {
            &:after {
                background: rgba(#000, 1);
            }
        }
    }
}

.tour__cancel,
.tour__next,
.tour__prev {
    @extend .uk-button-primary;
    padding: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;

    width: 100%;
    height: 100%;

}

.tour__next:not([disabled]) {
    box-shadow: 0 0 0 0 rgba($global-emphasis-color, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($global-emphasis-color, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($global-emphasis-color, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($global-emphasis-color, 0);
    }
}
</style>
