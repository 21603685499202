import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Booth from "./Booth.vue";
import Check from "./Check.vue";
import VModal from "vue-js-modal";
import Notifications from "vue-notification";
import VueFriendlyIframe from "vue-friendly-iframe";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Meta from "vue-meta";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { install as VueMarkdown } from "vue-markdown/src/build";

// -----------------------
// FOR DEVELOPMENT
// -----------------------
const force_isChina_inDev = false; // If activated, application will start in 'china' mode
// -----------------------

UIkit.use(Icons);
Vue.use(VueRouter);
Vue.use(VueMarkdown);
Vue.use(VModal);
Vue.use(Notifications);
Vue.use(VueFriendlyIframe);
Vue.use(Meta);
Vue.config.productionTip = false;

const VaPlugin = new Vue();
VaPlugin.install = function (Vue) {
  // 1. add global method or property
  Vue.isSmall = function () {
    return window.innerWidth < 960;
  };
};

Vue.use(VaPlugin);

// _vaData is filled after initial ajax-calls
// based on: https://stackoverflow.com/a/43193455/1308363

const _vaData = new Vue();

_vaData.install = function () {
  Object.defineProperty(Vue.prototype, "_vaData", {
    get() {
      return _vaData;
    },
  });
};

Vue.use(_vaData);

// set apiBase (for china cn domain)
const isCn =
  process.env.NODE_ENV === "development"
    ? (_vaData.isCn = force_isChina_inDev)
    : (_vaData.isCn = window.location.origin.endsWith(".cn"));

_vaData.apiBase = isCn
  ? process.env.VUE_APP_CN_API_BASE
  : process.env.VUE_APP_API_BASE;

// global event bus
// based on: https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/
export const bus = new Vue();

const routes = [
  {
    path: "",
    component: Booth,
  },
  {
    path: "/check",
    component: Check,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

new Vue({
  render: (h) => h(App),
  router,
  components: {
    App,
  },
}).$mount("#app");
