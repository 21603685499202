<template>
<div :style="cssProps">

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="c-va_modal__container">

    <aside class="uk-width-1-3 uk-margin-small-right uk-box-shadow-medium uk-flex uk-flex-column">
      <!-- aside -->
      <div class="uk-flex-1 body">

        <div class="body__head">
          <!-- top -->
          <div v-html="content.title"></div>

          <div class="series_image">
            <img class="series_image__product" :src="content.image" alt="title">
            <img class="series_image__award" v-if="content.award" :src="content.award" />
          </div>

        </div>

        <vue-custom-scrollbar class="body__main" :settings="scrollbar.settings">
          <!-- center, scrollable -->
          <Benefits :items="content.benefits" class="series_benefits uk-margin uk-margin-medium-top" />
        </vue-custom-scrollbar>

        <div class="body__bottom">
          <hr>
          <!-- bottom -->
          <div class="uk-grid uk-grid-small uk-child-width-1-2@l uk-child-width-1-1 links">
            <div>
              <a v-if="content.catalogLink" class="content-link-button uk-button uk-button-primary uk-width-1-1@m" :href="content.catalogLink" :title="strings.to_catalouge" target="_blank">{{strings.to_catalouge || 'Catalogue'}}</a> <br>
            </div>
            <div>
              <a v-if="content.websiteLink" class="content-link-button uk-button uk-button-primary uk-width-1-1@m" :href="content.websiteLink" :title="strings.to_catalouge" target="_blank">{{strings.to_website || 'Website'}}</a>
            </div>
          </div>
        </div>

      </div>
    </aside>

    <main class="uk-width-expand uk-background-default uk-box-shadow-medium c-va_modal__main main">
      <!-- main -->

      <VueSlickCarousel v-bind="slickSettings" @beforeChange="beforeChangeCarousel" @afterChange="afterChangeCarousel" @init="onInitCarousel" ref="carousel">

        <SlideZimmer class="slide" v-for="slide in content.slides" :key="slide.index" :title="slide.title" :media="slide.media">
          <template v-if="slide.type === 'possibleApplications'">
            <!-- first slide -->
            <h3>{{slide.branchesTitle}}</h3>
            <div v-html="slide.branches"></div>
            <h3>{{slide.possibleApplicationsTitle}}</h3>
            <div v-html="slide.possibleApplications"></div>
          </template>
          <template v-else-if="slide.type === 'sectionalView'">
            <!-- second slide -->
            <SectionalViewText :items="slide.sectionalViewText" />
          </template>
          <template v-else>
            <!-- all other slides -->
            <div v-html="slide.body"></div>
          </template>
        </SlideZimmer>
      </VueSlickCarousel>

    </main>
  </div>

</div>
</template>

<script>
import {
  bus
} from '../../main'

import VueSlickCarousel from 'vue-slick-carousel'
import SlideZimmer from './SlideZimmer.vue';
import Benefits from '../Benefits.vue';
import SectionalViewText from '../SectionalViewText.vue';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'ModalZimmerDefault_desktop',
  data: function() {

    // all data for modal
    const data = {
      content: this.$props.data,
      strings: this._vaData.strings,
      slickSettings: {
        "dots": true,
        "arrows": true
      },
      slideIndex: 0,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    }

    return data;
  },
  props: {
    data: Object
  },
  components: {
    VueSlickCarousel,
    SlideZimmer,
    Benefits,
    SectionalViewText,
    vueCustomScrollbar
  },
  computed: {
    cssProps() {
      let customProps = {};
      for (const [key, value] of Object.entries(this.content.sliderIcons)) {
        customProps[`--${key}-icon`] = `url("../slidericons/${value}.svg")`;
      }
      return customProps
    }
  },
  created() {},
  mounted() {
    console.log('Modal Data: ', this.$props.data)
    document.querySelectorAll('.c-va_modal__container video').forEach(($video) => {
      $video.addEventListener('play', () => {
        bus.$emit('video_started', {
          source: $video.getAttribute('src'),
          atTime: parseInt($video.currentTime),
          content: {
            id: this.id,
            title: this.title
          },
          slide: {
            index: this.slideIndex,
            title: this.content.slides[this.slideIndex].title
          }
        })
      })
      $video.addEventListener('pause', () => {
        bus.$emit('video_paused', {
          source: $video.getAttribute('src'),
          atTime: parseInt($video.currentTime),
          content: {
            id: this.id,
            title: this.title
          },
          slide: {
            index: this.slideIndex,
            title: this.content.slides[this.slideIndex].title
          }
        })
      })
    });
  },
  methods: {
    onInitCarousel() {
      bus.$emit('slide_showed', {
        index: this.slideIndex,
        title: this.content.slides[this.slideIndex].title,
        content: {
          id: this.id,
          title: this.title
        }
      })
    },
    afterChangeCarousel(i) {
      this.slideIndex = i
      bus.$emit('slide_showed', {
        index: this.slideIndex,
        title: this.content.slides[this.slideIndex].title,
        content: {
          id: this.id,
          title: this.title
        }
      })

      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      const $iframe = $currentSlide.querySelector('iframe');
      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const iframeSrc = $iframe.src;
        $iframe.src = iframeSrc;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.body,
.main {
    background: #fff;
    border-radius: 3px;
}

.body {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .body__bottom,
    .body__head {
        padding: $padding-small-padding $padding-padding;
        margin: 0;
    }

    .body__main {
        // background: #f3f3f3;
        flex-grow: 1;
        overflow: hidden;
        overflow-y: scroll;
        @extend .uk-padding;
        @extend .uk-padding-remove-vertical;

    }
}

.links {
    > div:first-child {
        margin-bottom: 10px;
    }
}
.content-link-button {
  padding: 0 20px;
}

.series_image {
    position: relative;
    padding-top: 75%;

    .series_image__product {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center left;
    }
    .series_image__award {
        position: absolute;
        top: 0;
        right: 0;
        height: 50%;
        width: 50%;
        pointer-events: none;
        object-fit: contain;
        object-position: top right;
        z-index: 1;
    }
}
</style>
