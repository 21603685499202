<template>
<div class="uk-nav-dropdown">
  <button class="uk-button uk-button-primary" type="button">{{tours}}</button>
  <vue-custom-scrollbar class="dropdown" :uk-dropdown="position ? ('pos:' + position) : '' + '; mode:click;'" :settings="scrollbar.settings">

    <ul class="uk-nav uk-dropdown-nav">
      <!-- eslint-disable -->
      <template v-for="group in groups">
        <li class="uk-nav-header">{{ group.title }}</li>
        <li v-for="tour in group.tours" :key="tour.key">
          <a @click="onEntryClick(tour.uid)">{{tour.title}}</a>
        </li>
      </template>
      <!-- enable -->

    </ul>

  </vue-custom-scrollbar>
</div>
</template>


<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import {
  bus
} from '../main'


export default {
  name: 'TourSelector',
  props: {
    position: String
  },
  data: function() {
    return {
      tours: this._vaData.strings.tours || 'Tours',
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    }
  },
  computed: {
    groups: function() {

      let groups = [];
      let i = 0;
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(this._vaData.typo3Tours)) {

        groups.push({
          title: value.title,
          tours: value.items,
          key: i++
        })

      }

      return groups;
    }
  },
  components: {
    vueCustomScrollbar
  },

  methods: {
    onEntryClick(uid) {
      console.log(uid)
      bus.$emit('start_tour', uid);
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
    max-height: 66vh;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 3px;
    user-select: none;
    max-width: calc(85vw - 2 * 25px);

    ul {
        a {
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
