<template>
<div class="uk-background-default uk-box-shadow-medium mega-menu">

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="uk-padding">
    <ul class="mega-menu__level mega-menu__level--0">
      <!-- level 0 title-->
      <li class="mega-menu__title">
        <h3 class="uk-margin-remove uk-text-secondary uk-text-uppercase">{{title}}</h3>
      </li>
      <!-- level 0 contents -->
      <li v-for="entry in contents" :key="entry.id">
        <a href="#" @click="showContent(entry.id)" v-html="entry.title"></a>
      </li>
    </ul>
  </div>

  <!-- level 0 children   -->
  <div class="uk-padding uk-padding-remove-top">
    <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l" uk-grid>

      <div v-for="entry in children" :key="entry.id">
        <ul class="mega-menu__level mega-menu__level--1">
          <!-- level 1 title -->
          <li class="mega-menu__title">
            <h4 class="uk-margin-remove uk-text-secondary uk-text-uppercase">{{entry.title}}</h4>
          </li>
          <!-- level 1 contents -->

          <li v-for="entry in entry.contents" :key="entry.id">
            <a href="#" @click="showContent(entry.id)" v-html="entry.title"></a>
          </li>
          <!-- level 1 children   -->
          <li v-if="entry.children && entry.children.length">
            <ul v-for="entry in entry.children" :key="entry.id" class="mega-menu__level mega-menu__level--2">

              <!-- level 2 title-->
              <li class="mega-menu__title">
                <h5 class="mega-menu__title uk-margin-remove uk-text-secondary uk-text-uppercase">{{entry.title}}</h5>
              </li>

              <li>
                <ul class=" mega-menu__level mega-menu__level--3">
                  <!--  level 2 contents -->
                  <li v-for="entry in entry.contents" :key="entry.id">
                    <a href="#" @click="showContent(entry.id)" v-html="entry.title"></a>
                  </li>



                  <li v-if="entry.children && entry.children.length">
                    <ul v-for="entry in entry.children" :key="entry.id" class="mega-menu__level mega-menu__level--2">

                      <!-- level 3 title-->
                      <li class="mega-menu__title">
                        <h6 class="mega-menu__title uk-margin-remove uk-text-secondary uk-text-uppercase">{{entry.title}}</h6>
                      </li>

                      <li>
                        <ul class=" mega-menu__level mega-menu__level--3">
                          <!--  level 3 contents -->
                          <li v-for="entry in entry.contents" :key="entry.id">
                            <a href="#" @click="showContent(entry.id)" v-html="entry.title"></a>
                          </li>
                        </ul>
                      </li>

                    </ul>
                  </li>



                </ul>
              </li>

            </ul>
          </li>

        </ul>
      </div>
    </div>

  </div>



</div>
</template>

<script>
import {
  bus
} from '../../main'

export default {
  name: 'MegaMenuModal',
  data: function() {
    return {
      strings: this._vaData.strings,
    };
  },
  props: {
    title: String,
    contents: Array,
    children: Array
  },
  components: {},
  methods: {
    showContent(id) {
      this.$modal.hide('menu-modal');
      setTimeout(() => {
        bus.$emit('show_content', id);
      }, 500)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mega-menu {

    a {
        position: relative;
        padding: 0 0 0 0.7em;
        display: inline-block;

        &:hover {
            text-decoration: none;

        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0.7em;
            display: inline-block;
            height: 0.25em;
            width: 0.25em;
            background-color: var(--primary-color);

        }
    }
    ul {

        padding: 0;
        list-style-type: none;
        li {
            padding: 0;
        };

    }
}
.mega-menu__level {
    position: relative;
}

ul.mega-menu__level--0 {
    margin: 0;
}

ul.mega-menu__level--1 {
    padding-left: 1.5em;
    position: relative;

    li {
        padding: 0.1em 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;

        display: block;
        height: 1em;
        width: 1em;
        background-image: url("./child.svg");
        background-repeat: no-repeat;
    }
}

ul.mega-menu__level--2 {
    padding-left: 1em;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.3em;
        display: block;
        height: 0.5em;
        width: 0.5em;
        background-image: url("./child.svg");
        background-repeat: no-repeat;
    }
}

.mega-menu__title {
    margin-bottom: 0.25em;
}
</style>
