<template>
  <div class="videoapp">
    <notifications position="bottom right" />

    <div v-if="isError" class="videoapp__error">
      <!-- ERROR -->
      <span class="emoji">😑</span>
      <h1>
        We are sorry!<br />
        Something went wrong.
      </h1>
      <div class="uk-alert-danger" uk-alert>
        {{ isError }}
      </div>
      <p>If this error persists, please contact us.</p>
      <p>
        <a href="mailto:apps@visionsbox.de">apps@visionsbox.de</a>
        <br />
        <a href="tel:+49 7803 9789030">+49 7803 9789030</a>
      </p>
    </div>
    <div v-else-if="isLoading" class="videoapp__loading">
      <!-- LOADER -->
      <div>
        <i uk-spinner="ratio: 3"></i>
      </div>
      <p>Loading ...</p>
    </div>
    <template v-else>
      <div class="videoapp__main" id="app">
        <!-- APP -->
        <div id="page-wrap" @click="userInteraction">
          <header class="videoapp__header">
            <Title :showBackBtn="showBackBtn"></Title>
            <a
              class="videoapp__logo"
              href="/"
              :title="strings.app_title"
              v-if="settings.logo"
            >
              <img
                :src="_vaData.apiBase + settings.logo"
                :alt="strings.app_title"
              />
            </a>
          </header>

          <ButtonMenu />
          <div class="videoapp__videothing-wrap">
            <ContactMenu position="center-right" />
            <VideoThing v-if="!isLoading" :settings="vtSettings" />
          </div>
          <MobileTitle :showBackBtn="showBackBtn"></MobileTitle>
          <footer class="videoapp__footer">
            <div uk-grid class="uk-flex-bottom uk-flex-between uk-grid">
              <div uk-grid class="uk-grid-small">
                <UkNavDropdown
                  v-if="locationsMenuEntries.length > 1"
                  :entries="locationsMenuEntries"
                  :title="strings.locations"
                  @onEntryClick="onDropDownNavEntryClick"
                />
                <!-- <UkNavDropdown v-if="_vaData.typo3Contents.length  > 0" :entries="_vaData.typo3Contents" :title="strings.contents" @onEntryClick="onDropDownNavEntryClick" /> -->
                <!-- TOURS -->
                <TourSelector />
              </div>

              <Tour />
            </div>
          </footer>
        </div>
      </div>

      <Offcanvas />
      <Content />
    </template>
  </div>
</template>

<script>
import UkNavDropdown from "./components/UkNavDropdown.vue";
import TourSelector from "./components/TourSelector.vue";
import Tour from "./components/Tour.vue";
import Offcanvas from "./components/Offcanvas.vue";
import Content from "./components/content/index.vue";
import ButtonMenu from "./components/buttonMenu/index.vue";
import Title from "./components/Title.vue";
import MobileTitle from "./components/MobileTitle.vue";
import ContactMenu from "./components/ContactMenu.vue";
import { bus } from "./main";
import languages from "./languages.js";

export default {
  name: "App",
  components: {
    VideoThing: () => import("./components/videoThing/VideoThing.vue"),
    UkNavDropdown,
    TourSelector,
    Tour,
    Content,
    ButtonMenu,
    Title,
    MobileTitle,
    ContactMenu,
    Offcanvas,
  },
  metaInfo() {
    return {
      title: this.strings ? this.strings.app_title : "Loading ...",
      meta: [
        {
          name: "desctiption",
          content: this.strings ? this.strings.description : "",
        },
        {
          property: "og:title",
          content: this.strings ? this.strings.app_title : "",
        },
        {
          property: "og:image",
          content: "expo.jpg",
        },
      ],
      script: [
        // Cookiebot
        // this._vaData.isCn
        // ? {}
        // : {
        //     id: "Cookiebot",
        //     src: "https://consent.cookiebot.com/uc.js",
        //     "data-cbid": "b644dac9-a4c4-40ab-93a9-6bf6f8931e36",
        //     "data-blockingmode": "auto",
        //     type: "text/javascript",
        //   },
      ],
    };
  },
  data: function () {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      isLoading: true,
      isError: false,
      vtSettings: false,
      locationTitle: false,
      locationHotspots: false,
      showBackBtn: false,
    };
  },
  computed: {
    locationsMenuEntries: function () {
      return this._vaData.locations
        ? this._vaData.locations.filter((l) => l.show_in_menu)
        : false;
    },
  },
  watch: {
    // https://stackoverflow.com/a/46331968/1308363
    "settings.show_hotspots": {
      handler(val) {
        if (val) {
          bus.$emit("show_hotspots");
        } else {
          bus.$emit("hide_hotspots");
        }
      },
    },
    "settings.show_navigation": {
      handler(val) {
        if (val) {
          bus.$emit("show_navigation");
        } else {
          bus.$emit("hide_navigation");
        }
      },
    },
    "settings.show_menus": {
      handler(val) {
        if (val) {
          bus.$emit("show_menus");
        } else {
          bus.$emit("hide_menus");
        }
      },
    },
  },
  methods: {
    async fetchData() {
      console.log("🔄 Loading data ...");
      this.isLoading = true;

      const params = new URLSearchParams(window.location.search);
      const langParameter = params.get("lang");

      // use zh on cn-domain, de on other domains as default
      let lang = this._vaData.isCn ? "zh" : "de";
      console.log("CN", this._vaData.isCn);

      if (langParameter) {
        lang = languages.find((l) => l.id === langParameter)
          ? langParameter
          : lang;
      } else {
        // try user lang
        var userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split("-")[0];
        lang = languages.find((l) => l.id === userLang) ? userLang : lang;
      }
      params.set("lang", lang);
      window.history.replaceState({}, "", `?${params}`);

      console.log("🏳️‍🌈 Language: " + lang);
      // set document lang (html attribute)
      document.documentElement.lang = lang.split("-")[0];
      this._vaData.currentLanguage = lang;

      // typo3-url
      const tdl =
        this._vaData.isCn || lang === "zh" || lang === "zh-trad" ? "cn" : "com";
      const name = lang === "de" ? "virtueller-messestand" : "virtual-booth";
      const typo3Url = `https://www.zimmer-group.${tdl}/${lang}/${name}/api.json`;

      await Promise.all([
        fetch(this._vaData.apiBase + "/locations/" + lang),
        fetch(this._vaData.apiBase + "/destinations"),
        fetch(this._vaData.apiBase + "/settings"),
        fetch(this._vaData.apiBase + "/strings/" + lang),
        fetch(typo3Url),
      ])
        .then((data) => {
          let failedRequests = data.filter((d) => d.ok === false);

          if (failedRequests.length) {
            let error = "Some API calls failed.";
            throw Error(error);
          }

          return data;
        })
        .then((data) => {
          return Promise.all(data.map((d) => d.json()));
        })
        .then((data) => {
          this._vaData.locations = this.locations = data[0];
          this._vaData.destinations = this.destinations = data[1];
          this._vaData.settings = this.settings = data[2];
          this._vaData.strings = this.strings = data[3];
          this._vaData.typo3Contents = this.typo3Contents =
            data[4].hotspots.map((h) => {
              h.dataSource = "typo3";
              return h;
            });
          this._vaData.typo3Tours = this.typo3Tours = data[4].tours;

          console.log("👨🏼‍🔧 Settings", this._vaData.settings);
          console.log("🏝️ Locations: ", this._vaData.locations);
          console.log("✈️ Destinations: ", this._vaData.destinations);
          console.log("📚 Contents: ", this._vaData.typo3Contents);
          console.log("📄 Strings: ", this._vaData.strings);
          console.log("📣 Tours: ", this._vaData.typo3Tours);

          if (!this.locations.length) {
            throw new Error("No location found!");
          }
          // Start main
          this.main();
        })
        .catch((err) => {
          // api not responding
          this.isError = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    main() {
      this.addHighlight();
      this.init();

      this.tagManager();
      this.showContent();
    },
    userInteraction() {
      if (!this.isLoading) {
        bus.$emit("playIfWechat");
      }
    },

    async addHighlight() {
      // Add Hotspots-information from typo3 to button from this.locations
      let curentButtons = [];
      for (let location of this.locations) {
        for (let strapi_hotspot of location.buttons) {
          if (strapi_hotspot.type === "hotspot") {
            const current_hotspot = this.typo3Contents.find((typo3_hotspot) => {
              // Look for the corresponding hotspots in the typo3 backend
              return typo3_hotspot.uid === +strapi_hotspot.contentId;
            });
            strapi_hotspot.isHighlight = current_hotspot.isHighlight || false;
            // Save location id
            if (strapi_hotspot.isHighlight) curentButtons.push(+location.id);
          }
        }
      }

      // Set "isHighlight" to navigation from start location
      // to hotspots with "isHighlight" property
      while (curentButtons.length) {
        // Array of parent navigations
        let nextLevelButtons = [];
        for (let location of this.locations) {
          for (let button of location.buttons) {
            if (
              button.type === "navigation" &&
              curentButtons.includes(+button.target_location.id)
            ) {
              nextLevelButtons.push(+location.id);
              button.isHighlight = true;
            }
          }
        }
        curentButtons = nextLevelButtons;
        // Brake when all parents are visited
      }
    },

    init() {
      const screensaverSetting = this.settings.screensaver;
      console.log("📽️ Starting videoThing");

      const params = new URLSearchParams(window.location.search);
      const defaultStartLocation = this.settings.start_location
        ? this.settings.start_location
        : this._vaData.locations[0].id;
      const startLocation = +params.get("location")
        ? +params.get("location")
        : defaultStartLocation;

      this.vtSettings = {
        startLocation: startLocation,
        preloadDestinationsAtStart: false,
        assetBase: this._vaData.apiBase,
        screensaver: {
          enabled: screensaverSetting.enabled ? true : false,
          timeout: screensaverSetting.timeout
            ? screensaverSetting.timeout
            : 120,
          cue:
            screensaverSetting.locations &&
            screensaverSetting.locations.length > 1
              ? screensaverSetting.locations
              : [],
          clearEvents: ["mousemove", "touchstart"],
          locationTime: screensaverSetting.time_at_location
            ? screensaverSetting.time_at_location
            : 7,
        },
        transitionOverlay: {
          color: "#fff",
          transitionDuration: 3000,
        },
      };

      bus.$emit("location_reached", this.settings.start_location, []);

      this.setCssVars();
    },
    setCssVars() {
      document
        .querySelector("body")
        .style.setProperty("--primary-color", this.settings.primary_color);
      document
        .querySelector("body")
        .style.setProperty("--secondary-color", this.settings.secondary_color);
      document
        .querySelector("body")
        .style.setProperty("--tertiary-color", this.settings.tertiary_color);
      document
        .querySelector("body")
        .style.setProperty("--muted-color", this.settings.muted_color);
    },

    showContent() {
      bus.$on("location_reached", (id, history) => {
        const location = this._vaData.locations.find((l) => l.id === id);
        console.info(
          `➡️ You reached location: ${location.title} (ID: ${id})`,
          location
        );
        this.locationTitle = location.title;
        this.locaationHotspots = location.buttons.filter(
          (b) => b.type === "hotspot"
        );
        setTimeout(() => {
          this.showBackBtn = history.length > 1 && location.show_back_btn;
        }, 1);

        const params = new URLSearchParams(window.location.search);
        params.set("location", id);
        window.history.replaceState({}, "", `?${params}`);
      });

      bus.$on("location_left", (id) => {
        const location = this._vaData.locations.find((l) => l.id === id);
        console.info(`⬅️ You left location: ${location.title} (ID: ${id})`);
        this.locationHotspots = false;
        this.showBackBtn = false;
      });

      const params = new URLSearchParams(window.location.search);
      const content = params.get("content");
      if (!content && this.settings.start_content) {
        params.set("content", this.settings.start_content);
        window.history.replaceState({}, "", `?${params}`);
      }
    },
    onDropDownNavEntryClick(entry) {
      switch (entry.type) {
        case "zimmer-default":
          bus.$emit("show_content", entry.uid);
          break;
        default:
          bus.$emit("go_to_location", entry.id);
          break;
      }
    },
    tagManager() {
      // TAGMANAGER EVENTS

      const isDev = process.env.NODE_ENV === "development";

      // partner
      if (this.$route.query.partner) {
        window.dataLayer?.push({ partnerId: this.$route.query.partner });
      }

      bus.$on("location_reached", (id) => {
        const location = this._vaData.locations.find((l) => l.id === id);
        const data = {
          event: "location_reached",
          id: location.id,
          title: location.title,
        };
        if (isDev) console.info("TM:location_reached", data);

        window.dataLayer?.push(data);
      });

      bus.$on("content_opened", (id) => {
        const content = this._vaData.typo3Contents.find((d) => {
          return d.uid == id;
        });
        const data = {
          event: "content_opened",
          id: content.id,
          title: content.title,
        };
        if (isDev) console.info("TM:content_opened", data);

        window.dataLayer?.push(data);
      });

      bus.$on("offcanvas_opened", () => {
        const data = {
          event: "offcanvas_opened",
        };
        if (isDev) console.info("TM:offcanvas_opened", data);
        window.dataLayer?.push(data);
      });

      bus.$on("contact_form_opened", () => {
        const data = {
          event: "contact_form_opened",
        };
        if (isDev) console.info("TM:contact_form_opened", data);
        window.dataLayer?.push(data);
      });

      bus.$on("contact_form_sent", (data) => {
        data.event = "contact_form_sent";
        if (isDev) console.info("TM:contact_form_sent", data);
        window.dataLayer?.push(data);
      });

      bus.$on("slide_showed", (data) => {
        data.event = "slide_showed";
        if (isDev) console.info("TM:slide_showed", data);
        window.dataLayer?.push(data);
      });

      bus.$on("video_started", (data) => {
        data.event = "video_started";
        if (isDev) console.info("TM:video_started", data);
        window.dataLayer?.push(data);
      });
      bus.$on("video_paused", (data) => {
        data.event = "video_paused";
        if (isDev) console.info("TM:video_paused", data);
        window.dataLayer?.push(data);
      });
      bus.$on("bookings_opened", (data) => {
        if (isDev) console.info("TM:bookings_opened", data);
        window.dataLayer?.push(data);
      });
    },
  },
  created() {
    this.fetchData();
  },

  mounted() {},
};
</script>

<style lang="scss">
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
@import "./videoapp.scss";
</style>
