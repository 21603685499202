<template>
<div class="app-info">
  <span v-if="showTitle">Version</span> {{version}}
</div>
</template>
<script>
export default {
  name: 'AppInfo',
  data() {

    return {
      version: process.env.VUE_APP_VERSION
    }
  },
  props: {
    showTitle: Boolean
  },
  created() {

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.app-info {
    font-size: 0.7em;
    text-align: left;
    opacity: 0.5;
    @extend .uk-margin;
}
</style>
