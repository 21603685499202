<template>
<div>
  <div v-for="(benefit, index) in items" :key="index">
    <!-- headline -->
    <template v-if="benefit.type==='headline'">
      <div v-for="(item, index) in benefit.items" :key="index">
        <h4 v-if="item.head" v-html="item.head"></h4>
        <h2 v-if="item.body" v-html="item.body"></h2>
      </div>
    </template>

    <!-- benefit -->
    <template v-if="benefit.type==='benefit'">
      <ul>
        <template v-for="(item, index) in benefit.items">
          <li v-if="item.head || item.body" :key="index" class="uk-margin-small">
            <strong v-if="item.head" v-html="item.head"></strong>
            <p v-if="item.body" class="uk-margin-remove" v-html="item.body"></p>
          </li>
        </template>
      </ul>
    </template>

    <!-- default -->
    <template v-else>
      <div v-if="benefit.head" v-html="benefit.head"></div>
      <div v-if="benefit.body" v-html="benefit.body"></div>
    </template>

  </div>
</div>
</template>
<script>
export default {
  name: 'Benefits',
  props: {
    items: Array
  }
}
</script>
<style lang="scss" scoped>
  
</style>
