<template lang="html">
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
