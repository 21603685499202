<template>
  <div>
    <div>
      <button class="uk-button uk-button-primary" type="button">
        <span uk-icon="icon: world"></span
        ><span>{{ currentLanguage.name }}</span>
      </button>
    </div>
    <div uk-dropdown="mode: click">
      <vue-custom-scrollbar>
        <ul class="uk-nav uk-dropdown-nav">
          <li
            v-for="language in languages"
            :key="language.id"
            :class="currentLanguage === language.id ? 'uk-active' : ''"
          >
            <a :title="language.name" @click="changeLanguage(language.id)">{{
              language.name
            }}</a>
          </li>
        </ul>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import languages from "../languages.js";
export default {
  name: "LanguageSwitcher",
  components: {
    vueCustomScrollbar,
  },
  props: {},
  computed: {
    currentLanguage: function () {
      return {
        id: this._vaData.currentLanguage,
        name: this.languages.find((l) => l.id === this._vaData.currentLanguage)
          .name,
      };
    },
  },
  methods: {
    changeLanguage: (language) => {
      const params = new URLSearchParams(window.location.search);
      params.set("lang", language);
      window.open("/?" + params.toString(), "_self");
    },
  },
  data: function () {
    return {
      languages: languages,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
[uk-dropdown] {
  border-radius: 3px;
  overflow: auto;
  padding: 0;

  .ps-container {
    padding: 25px;
    max-height: 66.66vh;
  }
}

.uk-button {
  padding: 0 15px;

  span:first-child {
    margin-right: 15px;
  }
}
</style>
