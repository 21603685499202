<template>
<div class="uk-position-relative slide">
  <div class="head">
    <div class="title">
      <h2>
        <template v-if="title && title.length > 0">{{title}}</template>
        <template v-else>&zwnj;</template>
      </h2>
    </div>
    <template v-if="media">
      <!-- iframe -->
      <div class="media" v-if="media.type === 'iframe' && media.url">
        <iframe :src="media.url" frameborder="0"></iframe>
      </div>

      <!-- image -->
      <div class="media" v-else-if="media.type === 'image' && media.url">
        <img :src="media.url" :alt="title">
      </div>

      <!-- youtube -->
      <div class="media" v-else-if="media.type === 'youtube'">
        <iframe :src="'https://www.youtube-nocookie.com/embed/' + media.youtubeId + '?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=1'" width="1920" height="1080" frameborder="0" allowfullscreen uk-responsive
                uk-video="automute: true"></iframe>
      </div>
      <!-- youku -->
      <div class="media" v-else-if="media.type === 'youku'">
        <iframe height="1080" width="1920" :src="'https://player.youku.com/embed/'+ media.youkuId + '?autoplay:true;mute=true'" allowScriptAccess="always" frameborder=0 allowfullscreen flashvars="isAutoPlay=true" uk-responsive uk-video="automute: true"></iframe>
      </div>
    </template>
  </div>
  <vue-custom-scrollbar class="body" :settings="scrollbar.settings">
    <!-- body content here via slot -->
    <slot></slot>
  </vue-custom-scrollbar>
</div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
export default {
  name: 'SlideZimmer',
  props: {
    title: {
      type: String,
      default: 'Zimmer GmbH'
    },
    media: {
      type: Object,
      default: function() {
        return {
          type: 'image',
          url: 'fallback.png'
        }
      }
    }
  },
  components: {
    vueCustomScrollbar
  },
  data: function() {
    return {
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.slide {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    overflow: hidden;
}

.title {
    padding: $padding-small-padding $padding-padding;
    & > * {
        margin: 0;
    }
}

.body {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: scroll;
    min-height: 0;
    padding: $padding-padding;
    // background: #f3f3f3;

}

.media {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    background-color: #f3f3f3;
    & > * {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    & > img {
        object-fit: contain;
        object-position: center;
    }
}
</style>
