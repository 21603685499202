<script>
import Vue from 'vue'
import {
  bus
} from '../../main'


import ModalZimmerDefault_desktop from './ModalZimmerDefault_desktop.vue'
import ModalZimmerDefault_mobile from './ModalZimmerDefault_mobile.vue'

const parseTypo3Data = (data) => {

  // first two slides ...
  const slides = []
  if (data.webglLink) {
    // optional webgl slide
    slides.push({
      type: 'possibleApplications',
      title: data.profileTitle,
      media: {
        type: 'iframe',
        url: data.webglLink
      },
      branchesTitle: data.branchesTitle,
      branches: data.branches,
      possibleApplicationsTitle: data.possibleApplicationsTitle,
      possibleApplications: data.possibleApplications
    })
  }


  // optional sectionalView slide
  if (data.series.sectionalView.image) {
    slides.push({
      type: "sectionalView",
      title: data.series.sectionalView.title,
      media: {
        type: 'image',
        url: data.series.sectionalView.image
      },
      sectionalViewText: data.series.sectionalView.text
    })
  }

  // ... all other slides (customslides)
  for (const [key, slide] of Object.entries(data.customslides)) {
    // filter empty slides

    if (slide.mediatype === 'none' && (slide.title === '' || slide.title === null) && (slide.content === '' || slide.content === null)) {
      console.info('empty slide filtered', slide)
    } else {
      slides.push({
        key: key,
        title: slide.title,
        body: slide.content,
        media: (() => {

          let media = {};

          switch (slide.mediatype) {
            case 'image':
              media = {
                type: slide.mediatype,
                url: slide.image
              }
              break;
            case 'youtube':
              media = {
                type: slide.mediatype,
                youtubeId: slide.youtubeId
              }
              break;
            case 'youku':
              media = {
                type: slide.mediatype,
                youkuId: slide.youkuId
              }
              break;
            case 'iframe':
              media = {
                type: slide.mediatype,
                url: slide.url
              }
              break;
          }

          return media;
        })()
      })
    }
  }

  return {
    title: data.series.title,
    isHighlight: data.isHighlight,
    image: data.series.image,
    award: data.award,
    sliderIcons: data.settings.slidericons,
    benefits: data.series.benefits,
    catalogLink: data.catalogLink,
    websiteLink: data.websiteLink,
    slides: slides
  }

}

export default {
  name: 'Content',
  props: {},
  data(){
    return {
      currentID: null
    }
  },
  methods: {
    show(id) {
      this.currentID = id
      console.log('requested content: ' + id)

      const data = this._vaData.typo3Contents.find(d => d.uid === parseInt(id))
      console.log('requested data: ',data)

      if (!data) {
        this.$notify({
          type: 'error',
          title: 'Content with ID ' + id + ' not found in contents!',
          text: 'Perhaps it has been deleted.'
        });
        return false;
      }

      const isSmall = Vue.isSmall();
      const component = isSmall ? ModalZimmerDefault_mobile : ModalZimmerDefault_desktop;

      this.$modal.show(
        component, {
          data: parseTypo3Data(data)
        }, {
          name: 'content-modal',
          classes: `c-va_modal c-va_modal--${data.type} ${isSmall ? 'c-va_modal--isSmall' : ''}`,
          height: isSmall ? 'auto' : '97%',
          width: isSmall ? '100%' : '100%',
          scrollable: true
        },{
          'before-open': this.beforeOpenModal,
          'before-close': this.beforeCloseModal
        }
      )
    },
    beforeOpenModal() {
      bus.$emit('content_opened', this.currentID)
    },
    beforeCloseModal() {
      this.deleteParamsContent()
    },
    setParamsContent(id){
      const params = new URLSearchParams(location.search);
      params.set('content', id);
      window.history.replaceState({}, '', `?${params}`);
    },
    deleteParamsContent(){
      const params = new URLSearchParams(location.search);
      params.delete('content')
      window.history.replaceState({}, '', `?${params}`);
    },
    checkParamsContent(){
      const params = new URLSearchParams(window.location.search);
      const content = params.get('content');

      if (content) {
        bus.$emit("show_content", parseInt(content));
      }
    }
  },
  created() {
    bus.$on('show_content', (id, source) => {
      this.setParamsContent(id)
      this.show(id, source);
    })
  },
  mounted(){
    this.checkParamsContent()
  },
  render() {
    return false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
