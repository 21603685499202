<template>
<div>
  <div v-for="(text, index) in items" :key="index">
    <template v-if="text.type==='schemtext'">
      <ol>
        <li v-for="(item, index) in text.items" :key="index" class="uk-margin-small">
          <strong v-if="item.head" v-html="item.head"></strong>
          <p v-if="item.body" class="uk-margin-remove" v-html="item.body"></p>
        </li>
      </ol>
    </template>
    <template v-else>
      <div v-if="item.body" v-html="item.body"></div>
    </template>
  </div>
</div>
</template>
<script>
export default {
  name: 'SectionalViewText',
  props: {
    items: Array
  }
}
</script>
<style lang="scss" scoped>
  
</style>
