<template>
<div class="contactsMenu" :class="'uk-position-' + position">

  <div class="contactsMenu__container">

    <div v-for="(link, i) in links" :key="i" class="link" :class="{ 'link--open': i === openLink}">

      <template v-if="isPhoneLink(link.href)">
        <a class="link__text" v-if="isPhoneLink(link.href)" :href="link.href">
          <span>{{isPhoneLink(link.href) }}</span> {{link.isOpen}}
        </a>
        <div class="link__icon" :title="link.title" @click="toggleLink(i)">
          <i v-if="link.icon" :style="{ 'background-image': 'url(' + _vaData.apiBase + link.icon + ')' }"></i>
        </div>
      </template>
      <template v-else>
        <a class="link__icon" :href="link.href" :title="link.title" :target="'_' + link.target">
          <i v-if="link.icon" :style="{ 'background-image': 'url(' + _vaData.apiBase + link.icon + ')' }"></i>
        </a>
      </template>
    </div>

    <div v-if="form" class="link">

      <a class="link__icon" @click="showContactForm" :title="form.title">
        <i :style="{ 'background-image': 'url(./contact_form_icon.svg)' }"></i>
      </a>

    </div>
  </div>
</div>
</template>
<script>
import ContactForm from './ContactForm.vue'
import {
  bus
} from '../main'
export default {
  name: 'ContactMenu',
  data: function() {
    return {
      openLink: null,
      links: this._vaData.strings.contact_links,
      form: this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form')
    }
  },
  props: {
    position: {
      type: String,
      default: 'center-left',
      validator: (prop) => [
        'top-left',
        'center-left',
        'bottom-left',
        'top-right',
        'center-right',
        'top-left',
      ].includes(prop)
    }
  },
  methods: {
    isPhoneLink: function(href) {
      return (href.indexOf('tel:') > -1) ? href.replace('tel:', '') : false;
    },
    toggleLink: function(i) {
      this.openLink = (this.openLink) ? null : i;

    },
    showContactForm() {

      this.form.referer = null;

      this.$modal.show(
        ContactForm, {
          form: this.form
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  created() {
    bus.$on('show_contact_form', () => {
      this.showContactForm()
    })

    document.querySelector('.videoapp').addEventListener('click', (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      // click wherever to close open link
      if (path.indexOf(document.querySelector('.link--open')) === -1) {
        this.openLink = null;
      }
    })
  }
}
</script>
<style lang="scss" scoped>
$border-radius: 3px;
$border-color: $global-emphasis-color;
$text-background: #fff;

.contactsMenu {
    z-index: 2;
    @extend .uk-padding;
    @media (orientation: portrait) and (max-width: $breakpoint-large) {
        padding: 0 !important;
    }

}

.contactsMenu__container {
    --btn-size: 50px;
    width: var(--btn-size);
    display: flex;
    flex-direction: column;
}

.link {

    display: flex;
    margin: 0.2em 0;
    height: var(--btn-size);

    &.link--open {
        .link__text {
            max-width: 200px;
        }
    }

    .contactsMenu[class*='right'] & {
        flex-direction: row-reverse;
    }

}
.link__icon {
    @extend .uk-button;
    @extend .uk-button-primary;

    color: #fff;
    display: block;
    height: var(--btn-size);
    width: var(--btn-size);
    padding: 0;
    margin: 0;
    position: relative;
    flex-shrink: 0;
    border: 0;
    border-left: 2px solid $border-color;

    .contactsMenu[class*='right'] & {
        border-left: none;
        border-right: 2px solid $border-color;
    }

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .contactsMenu[class*='right'] & {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    i {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        filter: brightness(0) invert(1);
    };

}

.link__text {
    background-color: $text-background;
    color: $global-emphasis-color;
    height: 100%;
    max-width: 0;
    flex-shrink: 0;
    display: flex;
    overflow: hidden;
    transition: all 0.5s;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    span {
        margin: 0 1em;
    }

    &:hover {
        color: $global-emphasis-color;
        text-decoration: none;
    }
}
</style>
