<script>
import {
  bus
} from '../../main'

import MegaMenuModal from './MegaMenuModal.vue'


export default {
  name: 'ButtonMenu',
  props: {

  },
  methods: {
    show(menu) {

      this.$modal.show(
        MegaMenuModal, menu, {
          name: 'menu-modal',
          classes: `c-va_modal c-va_modal--menu`,
          width: '100%',
          height: 'auto',
          scrollable: true,
          shiftY: .3

        }
      )

    }
  },
  created() {
    bus.$on('show_button_menu', (e, menu) => {
      this.show(menu)
    })
  },
  render() {
    return false
  }
}
</script>

