<template>
<div>

  <span v-if="isOpen" class="toggle uk-position-top-left" :uk-icon="'icon:close; ratio: 2'" uk-toggle="target: #offcanvas" @click="playIfWechat"></span>
  <span v-if="!isOpen" class="toggle uk-position-top-left " :uk-icon="'icon:menu; ratio: 2'" uk-toggle="target: #offcanvas" @click="playIfWechat"></span>

  <div id="offcanvas" uk-offcanvas="overlay: true; flip:false;mode:reveal;" ref="offcanvas">
    <div class="uk-offcanvas-bar">
      <div class="uk-margin-medium title">
        <h3 class="title__main" :class="strings.app_subtitle ? 'uk-margin-remove-bottom' : ''">{{strings.app_title}}</h3>
        <span lcass="title__sub" v-if="strings.app_subtitle">{{strings.app_subtitle}}</span>
      </div>
      <EntryBox :entries="locations" :title="strings.locations" :onEntryClick="goToLocation"></EntryBox>
      <EntryBox :entries="_vaData.typo3Contents" :title="strings.contents" :onEntryClick="openContent"></EntryBox>

      <div class="contact-links">
        <ul class="uk-list">
          <li v-for="link in links" :key="link.id">
            <i v-if="link.icon" :style="{ 'background-image': 'url(' + _vaData.apiBase + link.icon + ')' }"></i>
            <a :href="link.href" :title="link.title" :target="'_' + link.target">{{getLinkText(link.href)}}</a>
          </li>
          <li>
            <a @click="showContactForm" v-if="form" :title="form.title">
              <i :style="{ 'background-image': 'url(./contact_form_icon.svg)' }"></i>{{form.title}}</a>
          </li>
        </ul>
      </div>
      <hr>
      <MetaLinks />
      <hr>
      <LanguageSwitcher />
      <hr>
      <AppInfo :showTitle="true" />

      <div v-if="_vaData.isCn" class="china-links">

        <div>
          <a href="https://beian.miit.gov.cn/" target="_blank" title="沪ICP备20023972号"><span>沪ICP备20023972号</span></a><br />
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502016814" target="_blank" title="沪公网安备 31011502016814号"><img src="../assets/psb-icon.png" alt=""><span>沪公网安备 31011502016814号</span></a>
        </div>
      </div>


    </div>
  </div>

</div>
</template>


<script>
import UIkit from 'uikit';
import EntryBox from './EntryBox.vue';
import MetaLinks from './MetaLinks.vue';
import AppInfo from './AppInfo.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';


import {
  bus
} from '../main'
import ContactForm from './ContactForm.vue'

export default {
  name: 'Offcanvas',
  components: {
    LanguageSwitcher,
    EntryBox,
    MetaLinks,
    AppInfo
  },
  props: {

  },
  computed: {

    form: function() {
      return this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form')
    }
  },
  data: function() {
    return {

      isOpen: false,
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locations: this._vaData.locations,

      links: this._vaData.strings.contact_links,
      toggle: {
        color: {
          checked: this._vaData.settings.primary_color,
          unchecked: '#bf6363',
          disabled: this._vaData.settings.muted_color
        }
      }
    }
  },

  mounted() {

    UIkit.util.on('#offcanvas', 'show', () => {
      this.isOpen = true;
      bus.$emit('offcanvas_opened');
    });
    UIkit.util.on('#offcanvas', 'hide', () => {
      this.isOpen = false;
    });
  },
  methods: {
    playIfWechat(){
      bus.$emit('playIfWechat');
    },
    getLinkText(href) {
      if (href.indexOf(':') > -1) {
        // works for mailto: and tel: hrefs
        return href.split(':')[1]
      } else {
        return href;
      }
    },
    openContent(id, type) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();

      setTimeout(() => {
        bus.$emit('show_content', id, type);
      }, 500)
    },
    goToLocation(id) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      bus.$emit('go_to_location', id);
    },
    showContactForm() {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      this.$modal.show(
        ContactForm, {
          form: this.form
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }, {
          'before-open': this.beforeOpenModal
        }
      )
    },
    beforeOpenModal() {
      bus.$emit('contact_form_opened')
    },
  }
}
</script>
<style scoped lang="scss">
a {
    color: $global-emphasis-color;

    &:hover {
        color: $global-emphasis-color;
        filter: brightness(110%);
        opacity: inherit;
        text-decoration: none;
    }
}

hr {
    opacity: 0.1;
}

h3,
h4,
h5 {
    text-transform: uppercase;
    font-weight: 400;
}

.china-links {

    a {
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: flex-end;
        font-size: 0.7em;
        img {
            height: 1.4em;
            margin-right: 0.7em;

        }

        span {

            opacity: 0.5;
        }

        &:hover {
            span {
                opacity: 1;
            }
            opacity: 1;
            text-decoration: none;
        }

    }
}

.contact-links {
    a {
        white-space: nowrap;

    }

    i {
        height: 1em;
        width: 1em;
        display: inline-block;
        margin-right: 0.5em;
        filter: brightness(0) invert(1);
        background-size: contain;
        background-position: center;
        margin-bottom: -.2em;
    };
}

.toggle {
    @extend .uk-padding;
    z-index: 1 !important;
    cursor: pointer;
}

.uk-offcanvas-bar {
    @extend .uk-padding-small;
    color: #fff;
    background-color: $global-color;
}

.title * {
    text-transform: uppercase;
}

.title__sub {}

.uk-offcanvas-flip .uk-offcanvas-reveal {
    left: 0;
    right: auto;
}
</style>
