<template>
<div>

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="uk-box-shadow-medium uk-margin-bottom card">
    <div class="uk-background-default uk-padding body">
      <!-- top -->
      <div v-html="content.title"></div>

      <div class="uk-grid uk-child-width-1-2@s">

        <div class="uk-margin">
          <div class="series_image">
            <img class="series_image__product" :src="content.image" alt="title">
            <img class="series_image__award" v-if="content.award" :src="content.award" />
          </div>
        </div>

        <div class="series_benefits">
          <Benefits :items="content.benefits" />
          <hr>
          <div class="links">
            <a v-if="content.catalogLink" class="uk-button uk-button-primary uk-width-1-1@m" :href="content.catalogLink" :title="strings.to_catalouge" target="_blank">{{strings.to_catalouge || 'Catalogue'}}</a> <br>
            <a v-if="content.websiteLink" class="uk-button uk-button-primary uk-width-1-1@m" :href="content.websiteLink" :title="strings.to_catalouge" target="_blank">{{strings.to_website || 'Website'}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SlideZimmer class="slide uk-background-default uk-box-shadow-medium uk-margin" v-for="(slide, index) in content.slides" :key="slide.index" :title="slide.title" :media="slide.media">
    <template v-if="index === 0">
      <!-- first slide -->
      <h3>{{slide.branchesTitle}}</h3>
      <div v-html="slide.branches"></div>
      <h3>{{slide.possibleApplicationsTitle}}</h3>
      <div v-html="slide.possibleApplications"></div>
    </template>
    <template v-else-if="index === 1">
      <!-- second slide -->
      <SectionalViewText :items="slide.sectionalViewText" />
    </template>
    <template v-else>
      <!-- all other slides -->
      <div v-html="slide.body"></div>
    </template>
  </SlideZimmer>

</div>
</template>

<script>
import SlideZimmer from './SlideZimmer.vue';
import Benefits from '../Benefits.vue';
import SectionalViewText from '../SectionalViewText.vue';


// optional style for arrows & dots
export default {
  name: 'ModalZimmerDefault_desktop',
  data: function() {

    // all data for modal
    const data = {
      content: this.$props.data,
      strings: this._vaData.strings
    }

    return data;
  },
  props: {
    data: Object
  },
  components: {
    SlideZimmer,
    Benefits,
    SectionalViewText
  },
  computed: {},
  created() {},
  mounted() {
    // console.log(this.$props.data)
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.body,
.slide {
    border-radius: 3px;
}

.body {}

.links {
    a:first-child {
        margin-bottom: 10px;
    }
}

.series_image {
    position: relative;
    padding-top: 75%;
    width: 100%;

    .series_image__product {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
    .series_image__award {
        position: absolute;
        top: 0;
        right: 0;
        height: 50%;
        width: 50%;

        object-fit: contain;
        object-position: top right;
        z-index: 1;
    }
}
</style>
