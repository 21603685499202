export default [
  {
    name: "Deutsch",
    id: "de",
  },
  {
    name: "English",
    id: "en",
  },
  {
    name: "US-English",
    id: "en-us",
  },
  {
    name: "Français",
    id: "fr",
  },
  {
    name: "Español",
    id: "es",
  },
  {
    name: "Italiano",
    id: "it",
  },
  {
    name: "Slovensko",
    id: "sk",
  },
  {
    name: "中 文",
    id: "zh",
  },
  {
    name: "Polski",
    id: "pl",
  },
  {
    name: "한국어",
    id: "ko",
  },
  {
    name: "عربي",
    id: "ar",
  },
  {
    name: "বাংলা",
    id: "bn",
  },
  {
    name: "हिंदी",
    id: "hi",
  },
  {
    name: "bahasa Indonesia",
    id: "id",
  },
  {
    name: "português",
    id: "pt",
  },
  {
    name: "русский",
    id: "ru",
  },
  {
    name: "ไทย",
    id: "th",
  },
  {
    name: "Türk",
    id: "tr",
  },
  {
    name: "Tiếng Việt",
    id: "vi",
  },
  {
    name: "中國傳統",
    id: "zh-trad",
  }
];
