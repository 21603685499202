<template lang="html">
  <div>
    <h1>Soon ...</h1>
    <!-- <div v-if="locations">
      <div v-for="(location, key) in lLocations" :key="location.id">
        {{ key }} : {{ location.title }}
      </div>
    </div> -->
  </div>
</template>

<script>
// import languages from "./languages.js";

export default {
  data: function () {
    // return {
    //   locations: [],
    //   languages: languages,
    // };
  },
  computed: {
    // lLocations: () => {
    //   return this.locations.map((l) => {
    //     return l;
    //   });
    // },
  },
  methods: {
    // async fetchData() {
    //   this.locationsLoading = true;
    //
    //   const locationUrls = languages.map((l) => {
    //     return this._vaData.apiBase + "/locations/" + l.id;
    //   });
    //
    //   // const stringsUrls = languages.map((l) => {
    //   //   return this._vaData.apiBase + "/strings/" + l.id;
    //   // });
    //
    //   // const allUrls = [...locationUrls, ...stringsUrls];
    //
    //   console.log("🔄 Loading locations ...");
    //
    //   if (localStorage.getItem("locations")) {
    //     this.locations = JSON.parse(localStorage.getItem("locations"));
    //     console.log(this.locations);
    //     return;
    //   }
    //
    //   await Promise.all(locationUrls.map((url) => fetch(url)))
    //     .then((data) => {
    //       let failedRequests = data.filter((d) => d.ok === false);
    //
    //       if (failedRequests.length) {
    //         let error = "Some API calls failed.";
    //         throw Error(error);
    //       }
    //
    //       return data;
    //     })
    //     .then((data) => {
    //       return Promise.all(data.map((d) => d.json()));
    //     })
    //     .then((data) => {
    //       this.locations = data;
    //       localStorage.setItem("locations", JSON.stringify(data));
    //     })
    //     .catch((err) => {
    //       // api not responding
    //       this.locationsError = err;
    //     })
    //     .finally(() => {
    //       this.locationsLoading = false;
    //     });
    // },
  },
  created() {
    // this.fetchData();
  },
};
</script>

<style lang="css" scoped></style>
