<template>
<div class="title">

  <div class="">

    <div>
      <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
        <div class="l-title" v-if="locationTitle">
          {{locationTitle}}
        </div>
      </transition>

      <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
        <div v-if="showBackBtn" style="transition-delay: .5s">
          <button class="back-btn" @click="goBack()">
            <i uk-icon="chevron-left"></i> {{strings.back}}</button>
        </div>
      </transition>
    </div>
  </div>
</div>
</template>
<script>
import {
  bus
} from '../main';

export default {
  name: 'Title',
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: false
    }
  },
  props: {
    showBackBtn: Boolean
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit('go_back');
    }
  },
  created() {

    this.locationTitle = this._vaData.locations.find(l => l.id === this._vaData.settings.start_location).title;

    bus.$on('location_reached', (id) => {
      this.locationTitle = this._vaData.locations.find(l => l.id === id).title
    })

    bus.$on('location_left', () => {
      this.locationTitle = false;
    })

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";

.title {
    @media (max-width: $breakpoint-large) {
        display: none;
    }

}

.l-title {

    overflow: hidden;
    font-size: 1.4em;
    font-weight: 600;
    color: $global-emphasis-color;
    text-transform: uppercase;
}

h2 {
    margin: 0;
    font-size: 1.4em;
    text-transform: uppercase;

}

.back-btn {

    @extend .uk-button-small;
    padding-left: 4px;
    margin-right: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;
    background-color: #fff;
    border: 1px solid $global-emphasis-color;
    color: $global-emphasis-color;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background-color: $global-emphasis-color;
        color: #fff;

    }

    i {
        margin-right: 5px;
    };

}
</style>
